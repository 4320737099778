import type { Theme } from '@mui/material';
import { alpha } from '@mui/material';
import type { PaletteMode } from 'mantis-theme/types/config';

export const themeOptions = (mode: PaletteMode) => ({
  typography: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: '2.375rem',
      lineHeight: 1.21,
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.875rem',
      lineHeight: 1.27,
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: 1.33,
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: 1.4,
    },
    h5: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    h6: {
      fontWeight: 400,
      fontSize: '0.9375rem',
      lineHeight: 1.57,
    },
  },
  palette: {
    action: {
      hover: mode === 'dark' ? alpha('#007CFF', 0.08) : '#E6F7FF',
      selected:
        mode === 'dark' ? alpha('#007CFF', 0.2) : alpha('#007CFF', 0.05),
    },
  },
});

export const componentsOverrides = (theme: Theme) => ({
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontWeight: 800,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 50,
        '&:last-child': {
          paddingBottom: 50,
        },
        [theme.breakpoints.down('sm')]: {
          padding: 25,
          '&:last-child': {
            paddingBottom: 25,
          },
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color:
          theme.palette.mode === 'dark'
            ? theme.palette.secondary.contrastText
            : theme.palette.primary.contrastText,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&:hover': {
          ...(theme.palette.mode === 'dark' && {
            backgroundColor: `${theme.palette.secondary.darker} !important`,
          }),
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.MuiFilledInput-root': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.divider
              : theme.palette.grey[100],
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        '&.MuiSelect-icon': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiChip: {
    defaultProps: {
      variant: 'combined',
    },
  },
});
