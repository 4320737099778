import { UserProvider } from '@auth0/nextjs-auth0/client';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import LinkedApolloProvider from 'apollo/LinkedApolloProvider';
import MantisThemeProvider from 'theme/MantisThemeProvider';
import { ErrorBoundaryWithFallback } from 'components/utils/ErrorBoundaryWithFallback';
import { ConsoleAppContextsWrapper } from 'context/ConsoleAppContextsWrapper';
import { useUserAuthStatus } from 'context/user-auth-status/useUserAuthStatus';
import { UserLoginGuard } from 'components/utils/user-login-guard/UserLoginGuard';
import type { FCWithLayoutAndAuth } from 'components/utils/mantis-theme-specific/utilTypes';
import { CubeJsAppWrapper } from 'components/app-wrappers/CubeJsAppWrapper';
import { PageAuthGuard } from 'components/utils/auth-policy-guards/PageAuthGuard';
import { UserAuthStatusContext } from 'context/user-auth-status/UserAuthStatusContext';

export default function App({ Component, pageProps }: AppProps) {
  const userAuthStatusContextValue = useUserAuthStatus();
  return (
    <ErrorBoundaryWithFallback>
      <UserProvider>
        <UserAuthStatusContext.Provider value={userAuthStatusContextValue}>
          <LinkedApolloProvider>
            <Head>
              <title>Assetario Console</title>
              <meta name="description" content="Assetario Console" />
              <link rel="icon" href="/favicon.ico" />
            </Head>
            <ConsoleAppContextsWrapper>
              <MantisThemeProvider Component={Component}>
                <UserLoginGuard>
                  <CubeJsAppWrapper>
                    <PageAuthGuard page={Component as FCWithLayoutAndAuth}>
                      <Component {...pageProps} />
                    </PageAuthGuard>
                  </CubeJsAppWrapper>
                </UserLoginGuard>
              </MantisThemeProvider>
            </ConsoleAppContextsWrapper>
          </LinkedApolloProvider>
        </UserAuthStatusContext.Provider>
      </UserProvider>
    </ErrorBoundaryWithFallback>
  );
}
