/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

import * as React from 'react';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import type * as Types from '../../types';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PrincipalFieldsFragment = {
  __typename?: 'Principal';
  email: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  deactivatedBy?: string | null;
  metadata: { __typename?: 'PrincipalMetadata'; displayName?: string | null };
  policyGroups: Array<{
    __typename?: 'PrincipalPolicyGroup';
    name: string;
    companyId?: string | null;
    projectId?: string | null;
    metadata: {
      __typename?: 'PolicyGroupMetadata';
      displayName?: string | null;
    };
  }>;
};

export type ListPrincipalsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ListPrincipalsQuery = {
  __typename?: 'Query';
  listPrincipals: Array<{
    __typename?: 'Principal';
    email: string;
    createdAt: string;
    updatedAt: string;
    active: boolean;
    deactivatedBy?: string | null;
    metadata: { __typename?: 'PrincipalMetadata'; displayName?: string | null };
    policyGroups: Array<{
      __typename?: 'PrincipalPolicyGroup';
      name: string;
      companyId?: string | null;
      projectId?: string | null;
      metadata: {
        __typename?: 'PolicyGroupMetadata';
        displayName?: string | null;
      };
    }>;
  }>;
};

export type SelfPrincipalQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SelfPrincipalQuery = {
  __typename?: 'Query';
  selfPrincipal: {
    __typename?: 'Principal';
    email: string;
    createdAt: string;
    updatedAt: string;
    active: boolean;
    deactivatedBy?: string | null;
    namedPolicies: Array<{
      __typename?: 'PrincipalNamedPolicy';
      name: string;
      companyId?: string | null;
      projectId?: string | null;
    }>;
    metadata: { __typename?: 'PrincipalMetadata'; displayName?: string | null };
    policyGroups: Array<{
      __typename?: 'PrincipalPolicyGroup';
      name: string;
      companyId?: string | null;
      projectId?: string | null;
      metadata: {
        __typename?: 'PolicyGroupMetadata';
        displayName?: string | null;
      };
    }>;
  };
};

export type ListAssignablePrincipalPolicyGroupsQueryVariables = Types.Exact<{
  principalId: Types.Scalars['String'];
}>;

export type ListAssignablePrincipalPolicyGroupsQuery = {
  __typename?: 'Query';
  listAssignablePrincipalPolicyGroups: Array<{
    __typename?: 'PolicyGroup';
    name: string;
    metadata: {
      __typename?: 'PolicyGroupMetadata';
      displayName?: string | null;
    };
  }>;
};

export type CreatePrincipalMutationVariables = Types.Exact<{
  principal: Types.PrincipalCreateInput;
}>;

export type CreatePrincipalMutation = {
  __typename?: 'Mutation';
  createPrincipal: boolean;
};

export type UpdatePrincipalMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  principal: Types.PrincipalUpdateInput;
}>;

export type UpdatePrincipalMutation = {
  __typename?: 'Mutation';
  updatePrincipal: boolean;
};

export type DeactivatePrincipalMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type DeactivatePrincipalMutation = {
  __typename?: 'Mutation';
  deactivatePrincipal: boolean;
};

export type ActivatePrincipalMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ActivatePrincipalMutation = {
  __typename?: 'Mutation';
  activatePrincipal: boolean;
};

export const PrincipalFieldsFragmentDoc = gql`
  fragment PrincipalFields on Principal {
    email
    createdAt
    updatedAt
    metadata {
      displayName
    }
    policyGroups {
      name
      metadata {
        displayName
      }
      companyId
      projectId
    }
    active
    deactivatedBy
  }
`;
export const ListPrincipalsDocument = gql`
  query ListPrincipals {
    listPrincipals {
      ...PrincipalFields
    }
  }
  ${PrincipalFieldsFragmentDoc}
`;
export type ListPrincipalsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListPrincipalsQuery,
    ListPrincipalsQueryVariables
  >,
  'query'
>;

export const ListPrincipalsComponent = (
  props: ListPrincipalsComponentProps
) => (
  <ApolloReactComponents.Query<
    ListPrincipalsQuery,
    ListPrincipalsQueryVariables
  >
    query={ListPrincipalsDocument}
    {...props}
  />
);

/**
 * __useListPrincipalsQuery__
 *
 * To run a query within a React component, call `useListPrincipalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPrincipalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPrincipalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPrincipalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListPrincipalsQuery,
    ListPrincipalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPrincipalsQuery, ListPrincipalsQueryVariables>(
    ListPrincipalsDocument,
    options
  );
}
export function useListPrincipalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPrincipalsQuery,
    ListPrincipalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPrincipalsQuery, ListPrincipalsQueryVariables>(
    ListPrincipalsDocument,
    options
  );
}
export type ListPrincipalsQueryHookResult = ReturnType<
  typeof useListPrincipalsQuery
>;
export type ListPrincipalsLazyQueryHookResult = ReturnType<
  typeof useListPrincipalsLazyQuery
>;
export type ListPrincipalsQueryResult = Apollo.QueryResult<
  ListPrincipalsQuery,
  ListPrincipalsQueryVariables
>;
export function refetchListPrincipalsQuery(
  variables?: ListPrincipalsQueryVariables
) {
  return { query: ListPrincipalsDocument, variables: variables };
}
export const SelfPrincipalDocument = gql`
  query SelfPrincipal {
    selfPrincipal {
      ...PrincipalFields
      namedPolicies {
        name
        companyId
        projectId
      }
    }
  }
  ${PrincipalFieldsFragmentDoc}
`;
export type SelfPrincipalComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SelfPrincipalQuery,
    SelfPrincipalQueryVariables
  >,
  'query'
>;

export const SelfPrincipalComponent = (props: SelfPrincipalComponentProps) => (
  <ApolloReactComponents.Query<SelfPrincipalQuery, SelfPrincipalQueryVariables>
    query={SelfPrincipalDocument}
    {...props}
  />
);

/**
 * __useSelfPrincipalQuery__
 *
 * To run a query within a React component, call `useSelfPrincipalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfPrincipalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfPrincipalQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfPrincipalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SelfPrincipalQuery,
    SelfPrincipalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfPrincipalQuery, SelfPrincipalQueryVariables>(
    SelfPrincipalDocument,
    options
  );
}
export function useSelfPrincipalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelfPrincipalQuery,
    SelfPrincipalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfPrincipalQuery, SelfPrincipalQueryVariables>(
    SelfPrincipalDocument,
    options
  );
}
export type SelfPrincipalQueryHookResult = ReturnType<
  typeof useSelfPrincipalQuery
>;
export type SelfPrincipalLazyQueryHookResult = ReturnType<
  typeof useSelfPrincipalLazyQuery
>;
export type SelfPrincipalQueryResult = Apollo.QueryResult<
  SelfPrincipalQuery,
  SelfPrincipalQueryVariables
>;
export function refetchSelfPrincipalQuery(
  variables?: SelfPrincipalQueryVariables
) {
  return { query: SelfPrincipalDocument, variables: variables };
}
export const ListAssignablePrincipalPolicyGroupsDocument = gql`
  query ListAssignablePrincipalPolicyGroups($principalId: String!) {
    listAssignablePrincipalPolicyGroups(principalId: $principalId) {
      name
      metadata {
        displayName
      }
    }
  }
`;
export type ListAssignablePrincipalPolicyGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListAssignablePrincipalPolicyGroupsQuery,
    ListAssignablePrincipalPolicyGroupsQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: ListAssignablePrincipalPolicyGroupsQueryVariables;
        skip?: boolean;
      }
    | { skip: boolean }
  );

export const ListAssignablePrincipalPolicyGroupsComponent = (
  props: ListAssignablePrincipalPolicyGroupsComponentProps
) => (
  <ApolloReactComponents.Query<
    ListAssignablePrincipalPolicyGroupsQuery,
    ListAssignablePrincipalPolicyGroupsQueryVariables
  >
    query={ListAssignablePrincipalPolicyGroupsDocument}
    {...props}
  />
);

/**
 * __useListAssignablePrincipalPolicyGroupsQuery__
 *
 * To run a query within a React component, call `useListAssignablePrincipalPolicyGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAssignablePrincipalPolicyGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAssignablePrincipalPolicyGroupsQuery({
 *   variables: {
 *      principalId: // value for 'principalId'
 *   },
 * });
 */
export function useListAssignablePrincipalPolicyGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListAssignablePrincipalPolicyGroupsQuery,
    ListAssignablePrincipalPolicyGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListAssignablePrincipalPolicyGroupsQuery,
    ListAssignablePrincipalPolicyGroupsQueryVariables
  >(ListAssignablePrincipalPolicyGroupsDocument, options);
}
export function useListAssignablePrincipalPolicyGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAssignablePrincipalPolicyGroupsQuery,
    ListAssignablePrincipalPolicyGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAssignablePrincipalPolicyGroupsQuery,
    ListAssignablePrincipalPolicyGroupsQueryVariables
  >(ListAssignablePrincipalPolicyGroupsDocument, options);
}
export type ListAssignablePrincipalPolicyGroupsQueryHookResult = ReturnType<
  typeof useListAssignablePrincipalPolicyGroupsQuery
>;
export type ListAssignablePrincipalPolicyGroupsLazyQueryHookResult = ReturnType<
  typeof useListAssignablePrincipalPolicyGroupsLazyQuery
>;
export type ListAssignablePrincipalPolicyGroupsQueryResult = Apollo.QueryResult<
  ListAssignablePrincipalPolicyGroupsQuery,
  ListAssignablePrincipalPolicyGroupsQueryVariables
>;
export function refetchListAssignablePrincipalPolicyGroupsQuery(
  variables: ListAssignablePrincipalPolicyGroupsQueryVariables
) {
  return {
    query: ListAssignablePrincipalPolicyGroupsDocument,
    variables: variables,
  };
}
export const CreatePrincipalDocument = gql`
  mutation createPrincipal($principal: PrincipalCreateInput!) {
    createPrincipal(principal: $principal)
  }
`;
export type CreatePrincipalMutationFn = Apollo.MutationFunction<
  CreatePrincipalMutation,
  CreatePrincipalMutationVariables
>;
export type CreatePrincipalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatePrincipalMutation,
    CreatePrincipalMutationVariables
  >,
  'mutation'
>;

export const CreatePrincipalComponent = (
  props: CreatePrincipalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreatePrincipalMutation,
    CreatePrincipalMutationVariables
  >
    mutation={CreatePrincipalDocument}
    {...props}
  />
);

/**
 * __useCreatePrincipalMutation__
 *
 * To run a mutation, you first call `useCreatePrincipalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrincipalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrincipalMutation, { data, loading, error }] = useCreatePrincipalMutation({
 *   variables: {
 *      principal: // value for 'principal'
 *   },
 * });
 */
export function useCreatePrincipalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePrincipalMutation,
    CreatePrincipalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePrincipalMutation,
    CreatePrincipalMutationVariables
  >(CreatePrincipalDocument, options);
}
export type CreatePrincipalMutationHookResult = ReturnType<
  typeof useCreatePrincipalMutation
>;
export type CreatePrincipalMutationResult =
  Apollo.MutationResult<CreatePrincipalMutation>;
export type CreatePrincipalMutationOptions = Apollo.BaseMutationOptions<
  CreatePrincipalMutation,
  CreatePrincipalMutationVariables
>;
export const UpdatePrincipalDocument = gql`
  mutation updatePrincipal($email: String!, $principal: PrincipalUpdateInput!) {
    updatePrincipal(email: $email, principal: $principal)
  }
`;
export type UpdatePrincipalMutationFn = Apollo.MutationFunction<
  UpdatePrincipalMutation,
  UpdatePrincipalMutationVariables
>;
export type UpdatePrincipalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePrincipalMutation,
    UpdatePrincipalMutationVariables
  >,
  'mutation'
>;

export const UpdatePrincipalComponent = (
  props: UpdatePrincipalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePrincipalMutation,
    UpdatePrincipalMutationVariables
  >
    mutation={UpdatePrincipalDocument}
    {...props}
  />
);

/**
 * __useUpdatePrincipalMutation__
 *
 * To run a mutation, you first call `useUpdatePrincipalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrincipalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrincipalMutation, { data, loading, error }] = useUpdatePrincipalMutation({
 *   variables: {
 *      email: // value for 'email'
 *      principal: // value for 'principal'
 *   },
 * });
 */
export function useUpdatePrincipalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrincipalMutation,
    UpdatePrincipalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrincipalMutation,
    UpdatePrincipalMutationVariables
  >(UpdatePrincipalDocument, options);
}
export type UpdatePrincipalMutationHookResult = ReturnType<
  typeof useUpdatePrincipalMutation
>;
export type UpdatePrincipalMutationResult =
  Apollo.MutationResult<UpdatePrincipalMutation>;
export type UpdatePrincipalMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrincipalMutation,
  UpdatePrincipalMutationVariables
>;
export const DeactivatePrincipalDocument = gql`
  mutation deactivatePrincipal($id: String!) {
    deactivatePrincipal(id: $id)
  }
`;
export type DeactivatePrincipalMutationFn = Apollo.MutationFunction<
  DeactivatePrincipalMutation,
  DeactivatePrincipalMutationVariables
>;
export type DeactivatePrincipalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeactivatePrincipalMutation,
    DeactivatePrincipalMutationVariables
  >,
  'mutation'
>;

export const DeactivatePrincipalComponent = (
  props: DeactivatePrincipalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeactivatePrincipalMutation,
    DeactivatePrincipalMutationVariables
  >
    mutation={DeactivatePrincipalDocument}
    {...props}
  />
);

/**
 * __useDeactivatePrincipalMutation__
 *
 * To run a mutation, you first call `useDeactivatePrincipalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivatePrincipalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivatePrincipalMutation, { data, loading, error }] = useDeactivatePrincipalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivatePrincipalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivatePrincipalMutation,
    DeactivatePrincipalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivatePrincipalMutation,
    DeactivatePrincipalMutationVariables
  >(DeactivatePrincipalDocument, options);
}
export type DeactivatePrincipalMutationHookResult = ReturnType<
  typeof useDeactivatePrincipalMutation
>;
export type DeactivatePrincipalMutationResult =
  Apollo.MutationResult<DeactivatePrincipalMutation>;
export type DeactivatePrincipalMutationOptions = Apollo.BaseMutationOptions<
  DeactivatePrincipalMutation,
  DeactivatePrincipalMutationVariables
>;
export const ActivatePrincipalDocument = gql`
  mutation activatePrincipal($id: String!) {
    activatePrincipal(id: $id)
  }
`;
export type ActivatePrincipalMutationFn = Apollo.MutationFunction<
  ActivatePrincipalMutation,
  ActivatePrincipalMutationVariables
>;
export type ActivatePrincipalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ActivatePrincipalMutation,
    ActivatePrincipalMutationVariables
  >,
  'mutation'
>;

export const ActivatePrincipalComponent = (
  props: ActivatePrincipalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ActivatePrincipalMutation,
    ActivatePrincipalMutationVariables
  >
    mutation={ActivatePrincipalDocument}
    {...props}
  />
);

/**
 * __useActivatePrincipalMutation__
 *
 * To run a mutation, you first call `useActivatePrincipalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivatePrincipalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activatePrincipalMutation, { data, loading, error }] = useActivatePrincipalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivatePrincipalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivatePrincipalMutation,
    ActivatePrincipalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivatePrincipalMutation,
    ActivatePrincipalMutationVariables
  >(ActivatePrincipalDocument, options);
}
export type ActivatePrincipalMutationHookResult = ReturnType<
  typeof useActivatePrincipalMutation
>;
export type ActivatePrincipalMutationResult =
  Apollo.MutationResult<ActivatePrincipalMutation>;
export type ActivatePrincipalMutationOptions = Apollo.BaseMutationOptions<
  ActivatePrincipalMutation,
  ActivatePrincipalMutationVariables
>;
