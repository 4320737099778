// material-ui
import type { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TABLE ROW ||============================== //

export default function TableBody(theme: Theme) {
  const hoverStyle = {
    '&:not(.table-footer-pagination):hover': {
      backgroundColor: theme.palette.action.hover
    },
  };

  return {
    MuiTableBody: {
      styleOverrides: {
        root: {
          // '& .MuiTableRow-root': {
          //   '&:nth-of-type(even)': {
          //     backgroundColor: theme.palette.grey[50],
          //   },
          //   ...hoverStyle,
          // },
          '& .MuiTableRow-root': {
            ...hoverStyle,
          },
          '&.datatable .MuiTableCell-root': {
            fontSize: '0.875rem',
          },
        },
      },
    },
  };
}
