// material-ui
import type { Theme } from '@mui/material/styles';

// project import
import getColors from 'mantis-theme/utils/getColors';

// types
import type { ExtendedStyleProps } from 'mantis-theme/types/extended';

// ==============================|| CHIP - COLORS ||============================== //

function getColor({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { dark } = colors;

  return {
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2,
    },
  };
}

function getChipColorStyle({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { light, lighter, main, contrastText } = colors;

  return {
    color: contrastText,
    backgroundColor: lighter,
    borderColor: main,
    '&:hover': {
      backgroundColor: light,
      opacity: 1
    },
    '& .MuiChip-deleteIcon': {
      color: contrastText,
      opacity: 0.7,
      '&:hover': {
        opacity: 0.6
      },
    },
  };
}


function getColorStyle({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { light, lighter, main } = colors;

  return {
    color: main,
    backgroundColor: lighter,
    borderColor: light,
  };
}

// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme: Theme) {
  const defaultLightChip = getColorStyle({ color: 'secondary', theme });
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:active': {
            boxShadow: 'none',
          },
          '&.MuiChip-colorPrimary': getColor({ color: 'primary', theme }),
          '&.MuiChip-colorSecondary': getColor({ color: 'secondary', theme }),
          '&.MuiChip-colorError': getColor({ color: 'error', theme }),
          '&.MuiChip-colorInfo': getColor({ color: 'info', theme }),
          '&.MuiChip-colorSuccess': getColor({ color: 'success', theme }),
          '&.MuiChip-colorWarning': getColor({ color: 'warning', theme }),
          '&.MuiChip-colorChip_green': getChipColorStyle({ color: 'chip_green', theme }),
          '&.MuiChip-colorChip_blue': getChipColorStyle({ color: 'chip_blue', theme }),
          '&.MuiChip-colorChip_purple': getChipColorStyle({ color: 'chip_purple', theme }),
          '&.MuiChip-colorChip_cyan': getChipColorStyle({ color: 'chip_cyan', theme }),
          '&.MuiChip-colorChip_grey': getChipColorStyle({ color: 'chip_grey', theme }),
        },
        sizeLarge: {
          fontSize: '1rem',
          height: 40,
        },
        light: {
          ...defaultLightChip,
          '&.MuiChip-lightPrimary': getColorStyle({ color: 'primary', theme }),
          '&.MuiChip-lightSecondary': getColorStyle({
            color: 'secondary',
            theme,
          }),
          '&.MuiChip-lightError': getColorStyle({ color: 'error', theme }),
          '&.MuiChip-lightInfo': getColorStyle({ color: 'info', theme }),
          '&.MuiChip-lightSuccess': getColorStyle({ color: 'success', theme }),
          '&.MuiChip-lightWarning': getColorStyle({ color: 'warning', theme }),
        },
        combined: {
          border: '1px solid',
          ...defaultLightChip,
          '&.MuiChip-combinedPrimary': getColorStyle({
            color: 'primary',
            theme,
          }),
          '&.MuiChip-combinedSecondary': getColorStyle({
            color: 'secondary',
            theme,
          }),
          '&.MuiChip-combinedError': getColorStyle({ color: 'error', theme }),
          '&.MuiChip-combinedInfo': getColorStyle({ color: 'info', theme }),
          '&.MuiChip-combinedSuccess': getColorStyle({
            color: 'success',
            theme,
          }),
          '&.MuiChip-combinedWarning': getColorStyle({
            color: 'warning',
            theme,
          }),
        },
      },
    },
  };
}
