// material-ui
import type { Theme } from '@mui/material/styles';

// types
import type { ColorProps } from 'mantis-theme/types/extended';

// ==============================|| CUSTOM FUNCTION - COLORS ||============================== //

const getColors = (theme: Theme, color?: ColorProps) => {
  switch (color!) {
    case 'secondary':
      return theme.palette.secondary;
    case 'error':
      return theme.palette.error;
    case 'warning':
      return theme.palette.warning;
    case 'info':
      return theme.palette.info;
    case 'success':
      return theme.palette.success;
    case 'chip_green':
        return theme.palette.chip_green;
    case 'chip_cyan':
        return theme.palette.chip_cyan;
    case 'chip_purple':
        return theme.palette.chip_purple;
    case 'chip_magenta':
        return theme.palette.chip_magenta;
    case 'chip_blue':
        return theme.palette.chip_blue;
    case 'chip_yellow':
        return theme.palette.chip_yellow;
    case 'chip_grey':
        return theme.palette.chip_grey;
    default:
      return theme.palette.primary;
  }
};

export default getColors;
