import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';

export const UnauthorizedUserMessage = () => (
  <Grid
    container
    mt={6}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <Card>
      <CardContent>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography variant="h2" align="center">
              Unauthorized
            </Typography>
            <Typography color="textSecondary" align="center">
              Sorry, you don&apos;t have sufficient rights to see this page.
              Contact your organisation admin for more information.
            </Typography>
          </Stack>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);
