import { useContext, useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { onError } from '@apollo/client/link/error';
import { containsAuth0TokenError } from './Auth0TokenErrorService';
import { UserAuthStatusContext } from 'context/user-auth-status/UserAuthStatusContext';
import { omitTypename } from 'utils/omit';
import { containsGraphqlForbiddenError } from 'services/graphql-errors/GraphqlForbiddenErrorService';

interface Props {
  children: React.ReactNode;
}

const omitTypenameLink = new ApolloLink((operation, forward) => {
  operation.variables = omitTypename(operation.variables);
  return forward ? forward(operation) : null;
});

const cache = new InMemoryCache();

const LinkedApolloProvider = ({ children }: Props) => {
  const { setIsUserLoggedIn, setForbiddenOperation } = useContext(
    UserAuthStatusContext
  );

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, operation }) => {
        if (containsAuth0TokenError(graphQLErrors)) {
          setIsUserLoggedIn?.(false);
        }
        if (containsGraphqlForbiddenError(graphQLErrors)) {
          setForbiddenOperation?.(operation.operationName);
        }
      }),
    [setForbiddenOperation, setIsUserLoggedIn]
  );

  const uploadLink = useMemo(
    () => createUploadLink({ uri: '/api/graphql' }),
    []
  );

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          omitTypenameLink,
          errorLink,
          uploadLink as unknown as ApolloLink,
        ]),
        cache,
        connectToDevTools: true,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
          },
        },
      }),
    [errorLink, uploadLink]
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default LinkedApolloProvider;
