import { POLICY_GROUP_NAMES } from './PolicyGroupEnums';
import type { PrincipalNamedPolicy, PrincipalPolicyGroup } from 'types';
import { SUPERADMIN_NAMED_POLICY_NAME } from 'utils/assetario-auth-types';

export const getPolicyGroupNames = (userPolicyGroups: PrincipalPolicyGroup[]) =>
  userPolicyGroups.map((policyGroup) => policyGroup.name);

export const getNamedPolicyNames = (
  userNamedPolicies: PrincipalNamedPolicy[]
) => userNamedPolicies.map((namedPolicy) => namedPolicy.name);

export const shouldRenderForPolicyGroups = (
  userPolicyGroups: PrincipalPolicyGroup[],
  allowedPolicyGroupNames: string[]
): boolean => {
  const userPolicyGroupNames = getPolicyGroupNames(userPolicyGroups);
  if (
    userPolicyGroupNames.includes(POLICY_GROUP_NAMES['assetario-superadmin']) ||
    userPolicyGroupNames.includes(POLICY_GROUP_NAMES['assetario-all-admin'])
  ) {
    return true;
  }

  return !!userPolicyGroupNames.filter((groupName) =>
    allowedPolicyGroupNames.includes(groupName)
  ).length;
};

export const shouldRenderForNamedPolicies = (
  userNamedPolicies: PrincipalNamedPolicy[],
  allowedPolicyGroupNames: string[]
): boolean => {
  const userNamedPolicyNames = getNamedPolicyNames(userNamedPolicies);
  if (userNamedPolicyNames.includes(SUPERADMIN_NAMED_POLICY_NAME)) {
    return true;
  }

  return (
    userNamedPolicyNames.filter((groupName) =>
      allowedPolicyGroupNames.includes(groupName)
    ).length > 0
  );
};
