import type { GraphQLErrors } from '@apollo/client/errors';
import type { AccessTokenErrorCode as AccessTokenErrorCodeType } from '@auth0/nextjs-auth0';

export type Auth0AccesTokenErrorCode = `${AccessTokenErrorCodeType}`;

export const ACCESS_TOKEN_HANDLED_ERROR_CODES: Auth0AccesTokenErrorCode[] = [
  'ERR_EXPIRED_ACCESS_TOKEN',
  'ERR_MISSING_ACCESS_TOKEN',
  'ERR_MISSING_REFRESH_TOKEN',
  'ERR_MISSING_SESSION',
  'ERR_INSUFFICIENT_SCOPE',
];

export const containsAuth0TokenError = (
  graphQLErrors: GraphQLErrors = []
): boolean =>
  graphQLErrors.some(({ extensions }) =>
    ACCESS_TOKEN_HANDLED_ERROR_CODES.includes(
      extensions?.code as Auth0AccesTokenErrorCode
    )
  );
