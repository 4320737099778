import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

// third-party
import type { MessageFormatElement } from 'react-intl';
import { IntlProvider } from 'react-intl';

// project import
import useConfig from 'mantis-theme/hooks/useConfig';
import type { I18n } from 'mantis-theme/types/config';

// load locales files
// const loadLocaleData = (locale: I18n) => {
//   switch (locale) {
//     case 'fr':
//       return import('mantis-theme/utils/locales/fr.json');
//     case 'ro':
//       return import('mantis-theme/utils/locales/ro.json');
//     case 'zh':
//       return import('mantis-theme/utils/locales/zh.json');
//     case 'en':
//     default:
//       return import('mantis-theme/utils/locales/en.json');
//   }
// };
const loadLocaleData = (locale: I18n) =>
  import(`mantis-theme/utils/locales/${locale}.js`);

// ==============================|| LOCALIZATION ||============================== //

interface Props {
  children: ReactNode;
}

const Locales = ({ children }: Props) => {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState<
    Record<string, string> | Record<string, MessageFormatElement[]> | undefined
  >();

  useEffect(() => {
    loadLocaleData(i18n).then(
      (d: {
        default:
          | Record<string, string>
          | Record<string, MessageFormatElement[]>
          | undefined;
      }) => {
        setMessages(d.default);
      }
    );
  }, [i18n]);

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default Locales;
