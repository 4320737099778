export const SUPERADMIN_NAMED_POLICY_NAME = 'superadmin' as const;

export const NAMED_POLICY_NAMES = [
  'superadmin',
  'universal-self-auth',
  'assetario-all-principal-management',
  'assetario-all-data-integration-view',
  'assetario-all-data-integration-edit',
  'assetario-all-etl-view',
  'assetario-all-etl-expectation-state-edit',
  'assetario-all-etl-configuration-edit',
  'assetario-all-prediction-view',
  'assetario-all-prediction-model-pipeline-test',
  'assetario-all-prediction-model-pipeline-edit',
  'assetario-all-prediction-api-edit',
  'assetario-all-prediction-push-view',
  'assetario-all-prediction-push-edit',
  'assetario-all-feature-view',
  'assetario-all-offer-view',
  'assetario-all-offer-edit',
  'assetario-all-console-companies-projects-view',
  'assetario-all-console-companies-projects-edit',
  'assetario-all-ab-test-view',
  'assetario-all-ab-test-edit',
  'assetario-all-ab-test-api-webhook-edit',
  'assetario-all-ab-test-evaluations-edit',
  'client-console-companies-projects-view',
  'client-prediction-view',
  'client-console-sku',
] as const;

export const ASSETARIO_ALL_POLICY_NAMES = [
  'assetario-all-principal-management',
  'assetario-all-data-integration-view',
  'assetario-all-data-integration-edit',
  'assetario-all-etl-view',
  'assetario-all-etl-expectation-state-edit',
  'assetario-all-etl-configuration-edit',
  'assetario-all-prediction-view',
  'assetario-all-prediction-model-pipeline-test',
  'assetario-all-prediction-model-pipeline-edit',
  'assetario-all-prediction-api-edit',
  'assetario-all-prediction-push-view',
  'assetario-all-prediction-push-edit',
  'assetario-all-feature-view',
  'assetario-all-offer-view',
  'assetario-all-offer-edit',
  'assetario-all-console-companies-projects-view',
  'assetario-all-console-companies-projects-edit',
  'assetario-all-ab-test-view',
  'assetario-all-ab-test-edit',
  'assetario-all-ab-test-api-webhook-edit',
  'assetario-all-ab-test-evaluations-edit',
] as const;

export const CLIENT_POLICY_NAMES = [
  'client-console-companies-projects-view',
  'client-prediction-view',
  'client-console-sku',
] as const;

export type NamedPolicyName = (typeof NAMED_POLICY_NAMES)[number];
export type AssetarioAllNamedPolicyName =
  (typeof ASSETARIO_ALL_POLICY_NAMES)[number];
export type ClientNamedPolicyName = (typeof CLIENT_POLICY_NAMES)[number];
