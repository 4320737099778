import type { ReactElement, ReactNode } from 'react';
import React from 'react';

import type { NextPage } from 'next/types';
import { ConfigProvider } from 'mantis-theme/contexts/ConfigContext';

import ThemeCustomization from 'mantis-theme/themes';

import Locales from 'mantis-theme/components/Locales';
import ScrollTop from 'mantis-theme/components/ScrollTop';
import { ErrorBoundaryWithFallback } from 'components/utils/ErrorBoundaryWithFallback';

// extracted from mantis-theme/pages/_app.tsx

type LayoutProps = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface Props {
  Component: LayoutProps;
  children: React.ReactNode;
}

export default function MantisThemeProvider({ Component, children }: Props) {
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);
  return (
    <ConfigProvider>
      <ThemeCustomization>
        <Locales>
          <ScrollTop>
            <ErrorBoundaryWithFallback>
              {getLayout(<>{children}</>)}
            </ErrorBoundaryWithFallback>
          </ScrollTop>
        </Locales>
      </ThemeCustomization>
    </ConfigProvider>
  );
}
