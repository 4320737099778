import { useCallback, useContext } from 'react';
import {
  CardContent,
  Divider,
  Typography,
  Modal,
  Button,
  Stack,
} from '@mui/material';
import { UserAuthStatusContext } from 'context/user-auth-status/UserAuthStatusContext';
import MainCard from 'mantis-theme/components/MainCard';

export const ForbiddenOperationModal = () => {
  const { forbiddenOperation, setForbiddenOperation } = useContext(
    UserAuthStatusContext
  );

  const handleModalClose = useCallback(() => {
    setForbiddenOperation?.(null);
  }, [setForbiddenOperation]);

  if (!forbiddenOperation) {
    return null;
  }

  return (
    <Modal onClose={handleModalClose} open={!!forbiddenOperation}>
      {forbiddenOperation ? (
        <MainCard title="Forbidden operation" darkTitle content={false} modal>
          <CardContent>
            <Typography variant="body1">
              The operation <strong>{forbiddenOperation}</strong>
              &nbsp;is forbidden for your user.
            </Typography>
            <Typography variant="body1">
              In case you need to change your user privileges, please contact
              Assetario administrator.
            </Typography>
          </CardContent>
          <Divider />
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            sx={{ px: 2.5, py: 2 }}
          >
            <Button size="small" variant="outlined" onClick={handleModalClose}>
              OK
            </Button>
          </Stack>
        </MainCard>
      ) : (
        <></>
      )}
    </Modal>
  );
};
