import { useMediaQuery } from '@mui/material';
import { THEME_MODES } from './ModeSwitchService';
import useConfig from 'mantis-theme/hooks/useConfig';
import type { PaletteMode } from 'mantis-theme/types/config';

interface HookResult {
  paletteMode: PaletteMode;
  preferredSystemMode: string;
}

const { AUTO, DARK, LIGHT } = THEME_MODES;

export const useModeSwitch = (): HookResult => {
  const { mode } = useConfig();
  const isLightPreferredSystemMode = useMediaQuery(
    '(prefers-color-scheme: light)'
  );
  const preferredSystemMode = isLightPreferredSystemMode ? LIGHT : DARK;
  const paletteMode = (
    mode === AUTO ? preferredSystemMode : mode
  ) as PaletteMode;

  return {
    paletteMode,
    preferredSystemMode,
  };
};
