import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { ErrorBoundary } from '@sentry/nextjs';

const Fallback = () => {
  return (
    <Grid
      container
      spacing={2}
      mt={6}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Card>
        <CardContent>
          <Grid item xs={11}>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography variant="h1" align="center">
                This component has failed to render
              </Typography>
              <Typography color="textSecondary" align="center">
                <p>We&apos;re sorry for the inconvinience!</p>
                <p>
                  Details of this error were already sent to us to investigate.
                  <br />
                  In the meantime, you can try to:
                </p>
                - Refresh this page
                <br />
                - Logout and login again
                <br />
                <p>
                  If this happens repeatedly, please contact us and describe{' '}
                  <br />
                  each step you took to get to this error.
                </p>
              </Typography>
            </Stack>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const SimpleFallback = () => <>Failed to render a component</>;

interface Props {
  children: React.ReactNode;
  simpleFallback?: boolean;
}

export const ErrorBoundaryWithFallback = ({
  children,
  simpleFallback = false,
}: Props) => (
  <ErrorBoundary fallback={simpleFallback ? <SimpleFallback /> : <Fallback />}>
    {children}
  </ErrorBoundary>
);
