import type { ApolloError } from '@apollo/client';
import type { GraphQLErrorExtensions } from 'graphql';

export const getGraphQLErrorMessages = (
  error?: ApolloError
): string[] | null => {
  if (!error) {
    return null;
  }

  return error.graphQLErrors.map((error) => {
    const prodErrorMessage = error.message;

    const devErrorMessage = (
      error.extensions?.originalError as GraphQLErrorExtensions
    )?.message;

    return `${prodErrorMessage} ${devErrorMessage ?? ''}`;
  });
};
