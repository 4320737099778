import { CubeProvider } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';

export const CubeJsAppWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <CubeProvider
    cubejsApi={cubejs({
      apiUrl: '/api/cubejs',
      credentials: 'same-origin',
    })}
  >
    {children}
  </CubeProvider>
);
