// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function omitTypename(value: any): any {
  if (value == null) return value;

  if (Array.isArray(value)) {
    return value.map((item) => omitTypename(item));
  }

  if (value?.constructor === Object) {
    const { __typename, ...rest } = value;
    __typename;

    return Object.entries(rest).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: omitTypename(value) }),
      {}
    );
  }

  return value;
}
