import type { ReactNode} from 'react';
import { useCallback} from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import merge from 'lodash.merge';

// material-ui
import type { PaletteOptions} from '@mui/material';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import type {
  ThemeOptions,
  Theme,
  TypographyVariantsOptions,
} from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import {
  themeOptions as customThemeOptions,
  componentsOverrides as customComponentsOverrides,
} from '../../theme/theme';
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';
import type { CustomShadowProps } from 'mantis-theme/types/theme';
import useConfig from 'mantis-theme/hooks/useConfig';
import { useModeSwitch } from 'components/layout-specific/mode-switch/useModeSwitch';
import useLocalStorage from 'mantis-theme/hooks/useLocalStorage';

// types
type ThemeCustomizationProps = {
  children: ReactNode;
};

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({
  children,
}: ThemeCustomizationProps) {
  const { themeDirection, presetColor, fontFamily } = useConfig();
  const { paletteMode } = useModeSwitch();
  const [customPalette, setCustomPalette] = useLocalStorage<PaletteOptions | null>('custom_palette', null);
  
  const themePalette = useMemo(
    () => Palette(paletteMode, presetColor),
    [paletteMode, presetColor]
  );

  useEffect(() => {
    setCustomPalette(themePalette.palette)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themePalette])

  const themeTypography: TypographyVariantsOptions =
    useMemo<TypographyVariantsOptions>(
      () => Typography(paletteMode, fontFamily, themePalette),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [paletteMode, fontFamily]
    );
  const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(
    () => CustomShadows(themePalette),
    [themePalette]
  );

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
        },
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: customPalette ?? themePalette.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
    }),
    [themeDirection, customPalette, themePalette, themeTypography, themeCustomShadows]
  );

  const options = merge(themeOptions, customThemeOptions(paletteMode));
  const theme: Theme = createTheme(options);
  theme.components = merge(
    componentsOverride(theme),
    customComponentsOverrides(theme)
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
