export enum POLICY_GROUP_NAMES {
  'assetario-all-viewer' = 'assetario-all-viewer',
  'assetario-all-etl-engineer' = 'assetario-all-etl-engineer',
  'assetario-all-ml-engineer' = 'assetario-all-ml-engineer',
  'assetario-all-prediction-integrator' = 'assetario-all-prediction-integrator',
  'assetario-all-account-manager' = 'assetario-all-account-manager',
  'assetario-all-admin' = 'assetario-all-admin',
  'assetario-superadmin' = 'superadmin',
  'client-viewer' = 'client-viewer',
  'client-sku-manager' = 'client-sku-manager',
}
