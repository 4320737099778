import type { Theme } from '@mui/material/styles';

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundImage: 'none',
          boxShadow: `1px 3.5px 5.5px rgb(0 0 0 / ${
            theme.palette.mode === 'dark' ? 40 : 5
          }%)`,
          border: `1px solid ${theme.palette.divider}`,
        },
      },
    },
  };
}
