import { EntityMutationAlertContext } from 'components/entity-mutation-alert/EntityMutationAlertContext';
import { useEntityMutationAlertInfo } from 'components/entity-mutation-alert/useEntityMutationAlertInfo';

interface Props {
  children: React.ReactNode;
}

export const ConsoleAppContextsWrapper = ({ children }: Props) => {
  const entityMutationAlertValue = useEntityMutationAlertInfo();

  return (
    <EntityMutationAlertContext.Provider value={entityMutationAlertValue}>
      {children}
    </EntityMutationAlertContext.Provider>
  );
};
