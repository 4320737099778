import { useContext, useEffect } from 'react';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { ForbiddenOperationModal } from './ForbiddenOperationModal';
import { UserAuthStatusContext } from 'context/user-auth-status/UserAuthStatusContext';
import { DEFAULT_PATH } from 'services/DefaultPathsService';

interface Props {
  children: React.ReactNode;
}

export const UserLoginGuard = ({ children }: Props) => {
  const { isUserLoggedIn } = useContext(UserAuthStatusContext);
  const router = useRouter();

  useEffect(() => {
    if (!isUserLoggedIn) {
      const { apiLoginPath } = DEFAULT_PATH;
      const redirectUrl = `${apiLoginPath}?redirectTo=${encodeURI(
        window.location.href
      )}`;
      router.push(redirectUrl);
    }
  });

  if (isUserLoggedIn) {
    return (
      <>
        <ForbiddenOperationModal />
        {children}
      </>
    );
  }

  return (
    <Grid
      container
      mt={6}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Card>
        <CardContent>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h2" align="center">
                User logged out
              </Typography>
              <Typography color="textSecondary" align="center">
                Your access token has expired, need to re-login...
              </Typography>
            </Stack>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
