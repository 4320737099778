import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import ModeNightOutlinedIcon from '@mui/icons-material/ModeNightOutlined';
import { capitalize } from '@mui/material';

export const THEME_MODES = {
  AUTO: 'auto',
  DARK: 'dark',
  LIGHT: 'light',
};

const { AUTO, DARK, LIGHT } = THEME_MODES;

export const THEME_MODE_ICONS: { [key: string]: JSX.Element } = {
  [DARK]: <ModeNightOutlinedIcon key={DARK} fontSize="small" />,
  [LIGHT]: <WbSunnyOutlinedIcon key={LIGHT} fontSize="small" />,
};

export const getModeOptionText = (
  mode: string,
  preferredSystemMode: string
): string => {
  return mode === AUTO
    ? `Device Setting (${capitalize(preferredSystemMode)})`
    : `${capitalize(mode)}`;
};
