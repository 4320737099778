import { createContext } from 'react';

export type EntityMutationAlertInfo = {
  entityTypeName: string;
  addedEntityName?: string;
  updatedEntityName?: string;
} | null;

export type EntityMutationAlertContextValue = {
  entityMutationAlertInfo: EntityMutationAlertInfo;
  setEntityMutationAlertInfo: ((info: EntityMutationAlertInfo) => void) | null;
};

export const EntityMutationAlertContext =
  createContext<EntityMutationAlertContextValue>({
    entityMutationAlertInfo: null,
    setEntityMutationAlertInfo: null,
  });
