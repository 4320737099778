import { useState } from 'react';
import type {
  EntityMutationAlertContextValue,
  EntityMutationAlertInfo,
} from './EntityMutationAlertContext';

export const useEntityMutationAlertInfo =
  (): EntityMutationAlertContextValue => {
    const [entityMutationAlertInfo, setEntityMutationAlertInfo] =
      useState<EntityMutationAlertInfo>(null);

    return {
      entityMutationAlertInfo,
      setEntityMutationAlertInfo,
    };
  };
