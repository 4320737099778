import { useState } from 'react';
import type { UserAuthStatusContext } from './UserAuthStatusContext';

export const useUserAuthStatus = (): UserAuthStatusContext => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(true);
  const [forbiddenOperation, setForbiddenOperation] = useState<string | null>(
    null
  );

  return {
    isUserLoggedIn,
    forbiddenOperation,
    setIsUserLoggedIn,
    setForbiddenOperation,
  };
};
