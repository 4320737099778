import { createContext } from 'react';

export interface UserAuthStatusContext {
  isUserLoggedIn: boolean;
  forbiddenOperation: string | null;
  setIsUserLoggedIn: ((status: boolean) => void) | null;
  setForbiddenOperation: ((operationName: string | null) => void) | null;
}

export const UserAuthStatusContext = createContext<UserAuthStatusContext>({
  isUserLoggedIn: true,
  forbiddenOperation: null,
  setIsUserLoggedIn: null,
  setForbiddenOperation: null,
});
