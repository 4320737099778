import { useContext } from 'react';
import type { FCWithLayoutAndAuth } from '../mantis-theme-specific/utilTypes';
import { UnauthorizedUserMessage } from './UnauthorizedUserMessage';
import { useSelfPrincipalQuery } from 'graphql/_generated_/authServiceComponents';
import { UserAuthStatusContext } from 'context/user-auth-status/UserAuthStatusContext';
import { getGraphQLErrorMessages } from 'services/graphql-errors/GraphqlErrorsService';
import { shouldRenderForNamedPolicies } from 'services/policy-group/PolicyGroupService';
import { containsAuth0TokenError } from 'apollo/Auth0TokenErrorService';

interface Props {
  children: React.ReactNode;
  page: FCWithLayoutAndAuth;
}

export const PageAuthGuard = ({ children, page }: Props) => {
  const { data, loading, error } = useSelfPrincipalQuery({
    fetchPolicy: 'cache-first',
  });
  const { isUserLoggedIn } = useContext(UserAuthStatusContext);
  const { allowedNamedPolicies } = page;

  if (loading || !isUserLoggedIn) {
    return <></>;
  }

  if (error && !containsAuth0TokenError(error.graphQLErrors)) {
    throw new Error(getGraphQLErrorMessages(error)?.join(', '));
  }

  const userNamedPolicies = data?.selfPrincipal.namedPolicies;
  const shouldRenderPage =
    allowedNamedPolicies != null &&
    userNamedPolicies != null &&
    shouldRenderForNamedPolicies(userNamedPolicies, allowedNamedPolicies);

  if (allowedNamedPolicies == null || shouldRenderPage === true) {
    return <>{children}</>;
  }

  return <UnauthorizedUserMessage />;
};
