import type { PaletteMode } from '@mui/material';
import { grey } from '@mui/material/colors';

const ChipPalette = (mode: PaletteMode) => {
  if (mode === 'dark') {
    return {
      chip_green: {
        lighter: '#092B00',
        light: '#135200',
        main: '#52C41A',
        contrastText: '#B7EB8F',
      },
      chip_cyan: {
        lighter: '#002329',
        light: '#00474F',
        main: '#13C2C2',
        contrastText: '#87E8DE',
      },
      chip_purple: {
        lighter: '#120338',
        light: '#22075E',
        main: '#9254DE',
        contrastText: '#D3ADF7',
      },
      chip_magenta: {
        lighter: '#520339',
        light: '#780650',
        main: '#F759AB',
        contrastText: '#FFADD2',
      },
      chip_blue: {
        lighter: '#030852',
        light: '#061178',
        main: '#597EF7',
        contrastText: '#ADC6FF',
      },
      chip_yellow: {
        lighter: '#613400',
        light: '#874D00',
        main: '#FFC53D',
        contrastText: '#FFE58F',
      },
      chip_grey: {
        lighter: grey[700],
        light: grey[500],
        main: grey[200],
        contrastText: grey[100],
      },
    };
  }

  return {
    chip_green: {
      lighter: '#F6FFED',
      light: '#D9F7BE',
      main: '#52C41A',
      contrastText: '#237804',
    },
    chip_cyan: {
      lighter: '#E6FFFB',
      light: '#B5F5EC',
      main: '#13C2C2',
      contrastText: '#006D75',
    },
    chip_purple: {
      lighter: '#F9F0FF',
      light: '#EFDBFF',
      main: '#722ED1',
      contrastText: '#391085',
    },
    chip_magenta: {
      lighter: '#FFF0F6',
      light: '#FFD6E7',
      main: '#EB2F96',
      contrastText: '#9E1068',
    },
    chip_blue: {
      lighter: '#F0F5FF',
      light: '#D6E4FF',
      main: '#2F54EB',
      contrastText: '#10239E',
    },
    chip_yellow: {
      lighter: '#FFFBE6',
      light: '#FFF1B8',
      main: '#FAAD14',
      contrastText: '#AD6800',
    },
    chip_grey: {
      lighter: grey[100],
      light: grey[300],
      main: grey[400],
      contrastText: grey[700],
    },
  };
};

export default ChipPalette;
